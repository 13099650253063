import React from "react"

const PrivacyPage = () => (
  <>
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  #ppBody\n  {\n    font-size:11pt;\n    width:100%;\n    margin:0 auto;\n    text-align:justify;\n  }\n\n  .ppConsistencies\n  {\n    display:none;\n  }\n",
        }}
      />
      <div className="container">
        <h2>Our Privacy Policy</h2>
        <div id="ppBody">
          <div className="ppConsistencies">
            <div className="col-2">
              <div className="quick-links text-center">
                Information Collection
              </div>
            </div>
            <div className="col-2">
              <div className="quick-links text-center">Information Usage</div>
            </div>
            <div className="col-2">
              <div className="quick-links text-center">
                Information Protection
              </div>
            </div>
            <div className="col-2">
              <div className="quick-links text-center">Cookie Usage</div>
            </div>
            <div className="col-2">
              <div className="quick-links text-center">
                3rd Party Disclosure
              </div>
            </div>
            <div className="col-2">
              <div className="quick-links text-center">3rd Party Links</div>
            </div>
            <div className="col-2" />
            <div className="col-2" />
            <div className="col-2" />
          </div>
          <div style={{ clear: "both", height: "10px" }} />
          <div className="ppConsistencies">
            <div className="col-2">
              <div className="col-12 quick-links2 gen-text-center">
                Google AdSense
              </div>
            </div>
            <div className="col-2">
              <div className="col-12 quick-links2 gen-text-center coppa-pad">
                COPPA
              </div>
            </div>
            <div className="col-2">
              <div className="quick-links2 gen-text-center">
                Our Contact Information
                <br />
              </div>
            </div>
          </div>
          <div style={{ clear: "both", height: "10px" }} />
          <div className="innerText">
            This privacy policy has been compiled to better serve those who are
            concerned with how their 'Personally Identifiable Information' (PII)
            is being used online. PII, as described in US privacy law and
            information security, is information that can be used on its own or
            with other information to identify, contact, or locate a single
            person, or to identify an individual in context. Please read our
            privacy policy carefully to get a clear understanding of how we
            collect, use, protect or otherwise handle your Personally
            Identifiable Information in accordance with our website.
            <br />
          </div>
          <span id="infoCo" />
          <br />
          <div className="grayText">
            <strong>
              What personal information do we collect from the people that visit
              our blog, website or app?
            </strong>
          </div>
          <br />
          <div className="innerText">
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, phone number or other
            details to help you with your experience.
          </div>
          <br />
          <div className="grayText">
            <strong>When do we collect information?</strong>
          </div>
          <br />
          <div className="innerText">
            We collect information from you when you register on our site, place
            an order, subscribe to a newsletter, respond to a survey, fill out a
            form, Use Live Chat, Open a Support Ticket or enter information on
            our site.
          </div>
          <br />
          Provide us with feedback on our products or services{" "}
          <span id="infoUs" />
          <br />
          <div className="grayText">
            <strong>How do we use your information? </strong>
          </div>
          <br />
          <div className="innerText">
            {" "}
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:
            <br />
            <br />
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To personalize
            your experience and to allow us to deliver the type of content and
            product offerings in which you are most interested.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To improve our
            website in order to better serve you.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To allow us to
            better service you in responding to your customer service requests.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To administer a
            contest, promotion, survey or other site feature.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To send periodic
            emails regarding your order or other products and services.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To follow up with
            them after correspondence (live chat, email or phone inquiries)
          </div>
          <span id="infoPro" />
          <br />
          <div className="grayText">
            <strong>How do we protect your information?</strong>
          </div>
          <br />
          <div className="innerText">
            We do not use vulnerability scanning and/or scanning to PCI
            standards.
          </div>
          <div className="innerText">
            We only provide articles and information. We never ask for credit
            card numbers.
          </div>
          <div className="innerText">
            We do not use Malware Scanning.
            <br />
            <br />
          </div>
          <div className="innerText">
            Your personal information is contained behind secured networks and
            is only accessible by a limited number of persons who have special
            access rights to such systems, and are required to keep the
            information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL)
            technology.{" "}
          </div>
          <br />
          <div className="innerText">
            We implement a variety of security measures when a user enters,
            submits, or accesses their information to maintain the safety of
            your personal information.
          </div>
          <br />
          <div className="innerText">
            All transactions are processed through a gateway provider and are
            not stored or processed on our servers.
          </div>
          <span id="coUs" />
          <br />
          <div className="grayText">
            <strong>Do we use 'cookies'?</strong>
          </div>
          <br />
          <div className="innerText">
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer's hard drive through your Web browser (if
            you allow) that enables the site's or service provider's systems to
            recognize your browser and capture and remember certain information.
            For instance, we use cookies to help us remember and process the
            items in your shopping cart. They are also used to help us
            understand your preferences based on previous or current site
            activity, which enables us to provide you with improved services. We
            also use cookies to help us compile aggregate data about site
            traffic and site interaction so that we can offer better site
            experiences and tools in the future.
          </div>
          <div className="innerText">
            <br />
            <strong>We use cookies to:</strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Help remember and
            process the items in the shopping cart.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Understand and
            save user's preferences for future visits.
          </div>
          <div className="innerText">
            <br />
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser settings. Since browser is a little different,
            look at your browser's Help Menu to learn the correct way to modify
            your cookies.
            <br />
          </div>
          <br />
          <div className="innerText">
            If you turn cookies off, some features will be disabled. It won't
            affect the user's experience that make your site experience more
            efficient and may not function properly.
          </div>
          <br />
          <div className="innerText">
            However, you will still be able to place orders .
          </div>
          <br />
          <span id="trDi" />
          <div className="grayText">
            <strong>Third-party disclosure</strong>
          </div>
          <br />
          <div className="innerText">
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential. We may also release information
            when it's release is appropriate to comply with the law, enforce our
            site policies, or protect ours or others' rights, property or
            safety. <br />
            <br /> However, non-personally identifiable visitor information may
            be provided to other parties for marketing, advertising, or other
            uses.{" "}
          </div>
          <span id="trLi" />
          <br />
          <div className="grayText">
            <strong>Third-party links</strong>
          </div>
          <br />
          <div className="innerText">
            Occasionally, at our discretion, we may include or offer third-party
            products or services on our website. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </div>
          <span id="gooAd" />
          <br />
          <div className="blueText">
            <strong>COPPA (Children Online Privacy Protection Act)</strong>
          </div>
          <br />
          <div className="innerText">
            When it comes to the collection of personal information from
            children under the age of 13 years old, the Children's Online
            Privacy Protection Act (COPPA) puts parents in control. The Federal
            Trade Commission, United States' consumer protection agency,
            enforces the COPPA Rule, which spells out what operators of websites
            and online services must do to protect children's privacy and safety
            online.
            <br />
            <br />
          </div>
          <div className="innerText">
            We do not specifically market to children under the age of 13 years
            old.
          </div>
          <span id="canSpam" />
          <br />
          <div className="blueText">
            <strong>CAN SPAM Act</strong>
          </div>
          <br />
          <div className="innerText">
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
            <br />
            <br />
          </div>
          <div className="innerText">
            <strong>We collect your email address in order to:</strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send information,
            respond to inquiries, and/or other requests or questions
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Process orders and
            to send information and updates pertaining to orders.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send you
            additional information related to your product and/or service
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Market to our
            mailing list or continue to send emails to our clients after the
            original transaction has occurred.
          </div>
          <div className="innerText">
            <br />
            <strong>
              To be in accordance with CANSPAM, we agree to the following:
            </strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Not use false or
            misleading subjects or email addresses.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Identify the
            message as an advertisement in some reasonable way.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Include the
            physical address of our business or site headquarters.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Monitor
            third-party email marketing services for compliance, if one is used.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Honor
            opt-out/unsubscribe requests quickly.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Allow users to
            unsubscribe by using the link at the bottom of each email.
          </div>
          <div className="innerText">
            <strong>
              <br />
              If at any time you would like to unsubscribe from receiving future
              emails, you can email us at
            </strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Follow the
            instructions at the bottom of each email and we will promptly remove
            you from <strong>ALL</strong> correspondence.
          </div>
          <br />
          <span id="ourCon" />
          <br />
          <div className="blueText">
            <strong>Contacting Us</strong>
          </div>
          <br />
          <div className="innerText">
            If there are any questions regarding this privacy policy, you may
            contact us using the information below.
            <br />
            <br />
          </div>
          <div className="innerText">CoderSchool</div>
          <div className="innerText">12 Ton Dan</div>
          <div>Ward 13, District 4</div>
          <div className="innerText">Ho Chi Minh City, Vietnam</div>
          <div className="innerText">support@coderschool.vn</div>
          <div className="innerText">077-474-2431</div>
          <div className="innerText">
            <br />
            Last Edited on 2016-12-26
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPage
